$(document).ready(function () {
	$("[data-toggle-searchform]").on("click",
		function () {
			$("[data-toggle-searchform]").toggle();
			$("[data-searchform]").toggle();
		});

	$("[data-close-searchform]").on("click",
		function () {
			$("[data-searchform]").toggle();
			$("[data-toggle-searchform]").toggle();
		});
});