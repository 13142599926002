$(document).ready(function () {
	if ($(".js-seating-method").length) {
		console.log("Init seating method selection.");

		$(".js-seating-method input:checked").closest(".js-seating-method").addClass("active-seating-method");

		$(".js-seating-method").on("click", function () {
			$("div").removeClass("active-seating-method");
			$(this).addClass("active-seating-method");
			$(this).find("input").prop("checked", true);
		});
	}
});