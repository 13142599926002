
var PerformanceOrderModel = function(data) {
	var self = this;
	self.EventId = ko.observable(data.EventId);
	self.Tickets = ko.observableArray();

	for (var i in data.Options) {
		if (data.Options.hasOwnProperty(i)) {
			self.Tickets.push(new TicketModel(data.Options[i]));
		}
	}

	self.SelectSeat = function () {
		var data = self.ToDotNet();
		$.post("/plaatsen", data, function(returnedData) {
			return true;
		});
	}
	
	self.AddTickets = function() {
		var data = self.ToDotNet();
		$.post("/toevoegen/", data, function(returnedData) {
			return true;
		});
		return true;
	}


	self.ToDotNet = function () {
		var tickets = [];
		ko.utils.arrayForEach(self.Tickets(), function (ticket) {
			var data = ticket.ToDotNet();
			if (data !== null) {
				tickets.push(data);
			}
		});
		var data = {
			EventId: self.EventId(),
			Options: tickets
		}

		return data;
	}

}

var TicketModel = function (data) {
    var self = this;

	self.Id = ko.observable(data.Id);
	self.Amount = ko.observable(0);
	self.Description = ko.observable(data.Description);
	self.Price = ko.observable(data.Price);

    self.TotalPrice = ko.computed(function () {
		return (self.Price() * self.Amount());
	});

	self.ToDotNet = function () {
		var data = {
			Id: self.Id() || 0,
			Amount: self.Amount() || 0
		};
		
		return data;
	}
}

function formatCurrency(value) {
	return "€ " + value.toFixed(2);
}
