
var ShoppingCartModel = function(data) {
	var self = this;
	self.Lines = ko.observableArray();

	for (var i in data.Lines) {
		if (data.Lines.hasOwnProperty(i)) {
			self.Lines.push(new ShoppingCartLineModel(data.Lines[i]));
		}
	}

	self.UpdateAmount = function() {
        var data = self.ToDotNet();
	    console.log(data);
	    //$.post("/toevoegen/", data, function(returnedData) {
	    //	console.log("succes?");
	    //});
	}


	self.ToDotNet = function () {
		var lines = [];
		ko.utils.arrayForEach(self.Lines(), function (line) {
			var data = line.ToDotNet();
			if (data !== null) {
				lineSeparator.push(data);
			}
		});
		var data = {
			Lines: lines
		}

		return data;
	}

}

var ShoppingCartLineModel = function (data) {
    var self = this;
    //TODO: Implement 3 tickets 1 free
    //var ticketCount = $(".shoppingcart-container")[0].dataset.ticketCount;
	self.EventId = ko.observable(data.EventId);
	self.PerformanceId = ko.observable(data.PerformanceId);
	self.Amount = ko.observable(data.Amount);
	self.Artist = ko.observable(data.Artist);
	self.Title = ko.observable(data.Title);
	self.Description = ko.observable(data.Description);
	self.Date = ko.observable(data.PerformanceDate);
	self.Price = ko.observable(data.Price);

    //TODO: Implement 3 tickets 1 free
	self.TotalPrice = ko.computed(function() {
	    //if (self.Amount() >= ticketCount) {
	    //    return (self.Price() * (self.Amount() - 1));
	    //}
	    return (self.Price() * self.Amount());
	});

	self.ToDotNet = function () {
		var data = {
			EventId: self.EventId() || 0,
			PerformanceId: self.PerformanceId() || 0,
			Amount: self.Amount() || 0
		};
		
		return data;
	}
}

function formatCurrency(value) {
	return "€ " + value.toFixed(2);
}
