$('.dropdown-toggle').click(function (e) {
	if ($(document).width() > 768) {
		e.preventDefault();

		var url = $(this).attr('href');

		if (url !== '#') {

			window.location.href = url;
		}
	}
});




$(document).ready(function () {
	var menu = new NavigationMenu($(".nav-buttons"));
	menu.init();
});

function NavigationMenu(element) {

	var self = this;
	self.element = element;
	self.showMenu = false;

	function toggleMenu() {
		self.showMenu = !self.showMenu;
		if (self.showMenu) {
			self.bar.removeClass("hidden-xs").addClass('showMenu');
			self.menuIcon.removeClass("icon-hamburger").addClass("icon-close");
			self.menuBar.removeClass("hidden-xs");
		} else {
			self.bar.addClass("hidden-xs").removeClass('showMenu');
			self.menuIcon.removeClass("icon-close").addClass("icon-hamburger");
			self.menuBar.addClass("hidden-xs");
		}
	};

	function init() {
		self.menuBar = $('.navbar');

		self.bar = $('.menu-container', self.element);
		self.barxs = $('.menu-xs-container', self.element);

		self.menuToggle = $('.menu-toggle', self.element);
		self.menuIcon = $('.menu-icon', self.element);

		self.menucol = $('.nav-main-items', self.element);

		self.menuitems = $(".menu-items a", self.element);

		// set event handlers
		self.menuToggle.on('click', function (e) {
			self.toggleMenu();
			e.preventDefault();
		});
	};

	self.init = init;
	self.toggleMenu = toggleMenu;

	return self;
}