
$(document).ready(function() {
	console.log("init seatselection...");

	$(".js-select-seat").on("click", function () {
		var title = $(this).attr("title");
		var code = $(this).data("code");
		if ($(this).hasClass("selected")) {
			$(this).removeClass("selected");
			$("#seat-code-" + code).remove();
		} else {
			$(this).addClass("selected");

			$(".selected-seats").append("<div id=\"seat-code-" + code + "\">" + title + "<input type=\"hidden\" name=\"SelectedSeats[]\" value=\"" + code + "\"></div>");
		}
		return false;
	});
});