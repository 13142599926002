ko.extenders.numeric = function (target, precision) {
	var result = ko.dependentObservable({
		read: function () {
			var value = target().toString().replace(",", ".");
				console.log(value);
			if (isNaN(parseFloat(value))) {
				console.log("nan!");
				return 0.00;
			}
			return Math.abs(parseFloat(value).toFixed(precision));
		},
		write: target
	});

	result.raw = target;
	return result;
};

var TicketOverviewModel = function (data) {
	var self = this;
    self.TicketError = ko.observable(false);
    self.ErrorMessage = ko.observable("");
	self.EventId = ko.observable(data.EventId);
	self.PerformanceId = ko.observable(data.PerformanceId);
	self.ReservationId = ko.observable(data.ReservationId);

	self.PriceCodes = ko.observableArray();
	self.UpSelling = ko.observableArray();

	self.HasUpselling = ko.computed(function() {
		return self.UpSelling().length > 0;
	});

	self.Donation = ko.observable(0.00).extend({ numeric: 2 });
	self.VoedselBankDonation = ko.observable(0.00).extend({ numeric: 2 });
	self.NewFriend = ko.observable(false);

	self.TotalPrice = ko.computed(function () {
		var total = parseFloat(self.Donation()) + parseFloat(self.VoedselBankDonation());
		for (var i = 0; i < self.PriceCodes().length; ++i) {
			total += self.PriceCodes()[i].TotalPrice();
		}
		for (var i = 0; i < self.UpSelling().length; ++i) {
			total += self.UpSelling()[i].TotalPrice();
			}
		return total;
	});

	for (var i in data.PriceCode.PriceCodes) {
		if (data.PriceCode.PriceCodes.hasOwnProperty(i)) {
			self.PriceCodes.push(new PriceCode(data.PriceCode.PriceCodes[i]));
		}
	}

	for (var i in data.UpSelling) {
		if (data.UpSelling.hasOwnProperty(i)) {
			self.UpSelling.push(new UpSelling(data.UpSelling[i]));
		}
	}

    self.UpdateOrder = function () {
		var data = self.ToDotNet();
		$.post("/update/", data, function (returnedData) {
			if (returnedData.Status == "success") {
				self.TicketError(false);
				var href = $(".js-update-order").attr("href");
				window.location.href = href;
			} else {
                self.TicketError(true);
                self.ErrorMessage(returnedData.ErrorMessage);
			}
		});
	}

	self.ToDotNet = function () {
		var priceCodesArray = [];
		var upSellingArray = [];
		ko.utils.arrayForEach(self.PriceCodes(), function (priceCode) {
			var data = priceCode.ToDotNet();
			if (data !== null) {
				priceCodesArray.push(data);
			}
		});
		ko.utils.arrayForEach(self.UpSelling(), function (upSelling) {
			var data = upSelling.ToDotNet();
			if (data !== null) {
				upSellingArray.push(data);
			}
		});
		var priceCodeModel = { PriceCodes: priceCodesArray };
		var data = {
			EventId: self.EventId(),
			PerformanceId: self.PerformanceId(),
			PriceCode: priceCodeModel,
			Upselling: upSellingArray,
			Donation: self.Donation(),
			VoedselBankDonation: self.VoedselBankDonation(),
			NewFriend: self.NewFriend(),
			ReservationId: self.ReservationId()
		}

		return data;
	}

}

var PriceCode = function (data) {
    var self = this;
	self.Id = ko.observable(data.Id);
	self.Description = ko.observable(data.Description);
	self.Price = ko.observable(data.Price / 100);
    self.ActionCode = ko.observable(data.ActionCode);
	self.MaximumAmount = ko.observable(data.MaximumAmount);
	self.GroupDiscount = ko.observable(data.GroupDiscount);
	self.Amount = ko.observable(data.Amount);
  
	self.TotalPrice = ko.computed(function () {
	    return (self.Price() * self.Amount());
	});

	self.ToDotNet = function () {
		var data = {
			Id: self.Id() || 0,
            Amount: self.Amount() || 0,
			ActionCode: self.ActionCode() || ''
		};

		return data;
	}
}

var UpSelling = function (data) {
    var self = this;

	self.Id = ko.observable(data.Id);
	self.Title = ko.observable(data.Title);
	self.Price = ko.observable(data.Price / 100);
    self.Quantity = ko.observable(0);
	self.TotalPrice = ko.computed(function () {
		return (self.Price() * self.Quantity());
	});

	self.ToDotNet = function () {
		var data = {
			Id: self.Id() || 0,
			Quantity: self.Quantity() || 0
		};

		return data;
	}
}

function formatCurrency(value) {
	return "€ " + parseFloat(value).toFixed(2);
}
