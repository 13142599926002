$(document).ready(function () {
	var userMenu = new UserMenu($(".user-menu"));
	userMenu.init();
});

function UserMenu(element) {

	var self = this;
	self.element = element;
	self.showMenu = false;

	function toggleMenu() {
		self.showMenu = !self.showMenu;
		self.icon.toggleClass("hidden");
		if (self.showMenu) {
			self.userMenu.removeClass("hidden").addClass('showMenu');
		} else {
			self.userMenu.addClass("hidden").removeClass('showMenu');
		}
	};

	function init() {

		self.userMenu = $('.user-menu-items', self.element);

		self.menuToggle = $('.user-menu-toggle', self.element);

		self.logOut = $(".js-logout", self.element);

		self.icon = $(".icon-arrow", self.element);

		// set event handlers
		self.menuToggle.on('click', function (e) {
			self.toggleMenu();
			e.preventDefault();
		});

		self.logOut.on("click", function (e) {
			$(this).parents("form").submit();
			e.preventDefault();
		});
	};

	self.init = init;
	self.toggleMenu = toggleMenu;


	return self;
}