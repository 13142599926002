$(document).ready(function () {
	console.log("init sliders...");

	var bxSliderConfig = {
		mode: 'fade',
		auto: true,
		pause: 6000,
		touchEnabled: false,
		onSliderLoad: function (currentIndex) {
			var background = $(this).find(".slide-image").css("background-image");
			var backgroundElement = $(".js-slide-background");
			backgroundElement.css('background-image', background);
		},
		onSlideBefore: function (slideElement, oldIndex, newIndex) {
			var background = slideElement.find(".slide-image").css("background-image");
			var backgroundElement = $(".js-slide-background");
			backgroundElement.css('background-image', background);
		}
	};

	var bxSlider = $(".bx-slider").bxSlider(bxSliderConfig);

	var bxSliderConfigNews = {
		mode: 'fade',
		auto: false,
		pause: 6000,
		onSliderLoad: function (currentIndex) {
			var background = $(this).find(".slide-image").css("background-image");
			var backgroundElement = $(".js-slide-background");
			backgroundElement.css('background-image', background);
		},
		onSlideBefore: function (slideElement, oldIndex, newIndex) {
			var background = slideElement.find(".slide-image").css("background-image");
			var backgroundElement = $(".js-slide-background");
			backgroundElement.css('background-image', background);
		},
		minSlides: 2,
		maxSlides: 2,
		slideWidth: 555,
		infiniteLoop: true,
		mode: 'horizontal',
		moveSlides: 1,
		slideMargin: 30,
	};

	var bxSliderNews = $(".bx-slider-news").bxSlider(bxSliderConfigNews);


	$("[data-slider-button]").click(function (url) {
		event.stopPropagation();
		var url = $(this).data('url');
		window.location = url;
	})
});